/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ElementType, type FC } from "react";
import { type LayoutServiceData } from "~/foundation/Jss/useSitecoreContext";
import { type LoadableSitecoreContextRouteProps } from "./foundation/Multisite";
import { LoadableSitecoreContextRoute as RaceteqLoadableSitecoreContextRoute } from "./project/Raceteq/LoadableSitecoreContextRoute";
import { LoadableSitecoreContextRoute as AramcoWorldLoadableSitecoreContextRoute } from "./project/AramcoWorld/LoadableSitecoreContextRoute";
import { LoadableSitecoreContextRoute as AramcoStadiumLoadableSitecoreContextRoute } from "./project/AramcoStadium/LoadableSitecoreContextRoute";
import { LoadableSitecoreContextRoute as AramcoQafilahLoadableSitecoreContextRoute } from "./project/AramcoQafilah/LoadableSitecoreContextRoute";
import { LoadableSitecoreContextRoute as AramcoTechLoadableSitecoreContextRoute } from "./project/AramcoTech/LoadableSitecoreContextRoute";
import { JssRoute } from "./foundation/Jss/JssRoute";
import config from "./temp/config";

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

type AppRootProps = {
	ssrState?: LayoutServiceData;
	path: string;
	Router: any;
	siteName: string;
	routerContext: any;
};

const AppRoot: FC<AppRootProps> = (props) => {
	const { path, Router, ssrState, siteName } = props;
	const project = ssrState?.sitecore.context?.custom?.projectLayer || config.devProject?.projectName;
	if (!project) {
		throw "Project is not defined";
	}

	let SitecoreContextRoute: ElementType<LoadableSitecoreContextRouteProps> | null = null;

	if (project === "Raceteq") {
		SitecoreContextRoute = RaceteqLoadableSitecoreContextRoute;
	}

	if (project === "AramcoWorld") {
		SitecoreContextRoute = AramcoWorldLoadableSitecoreContextRoute;
	}

	if (project === "AramcoStadium") {
		SitecoreContextRoute = AramcoStadiumLoadableSitecoreContextRoute;
	}

	if (project === "AramcoQafilah") {
		SitecoreContextRoute = AramcoQafilahLoadableSitecoreContextRoute;
	}

	if (project === "AramcoTech") {
		SitecoreContextRoute = AramcoTechLoadableSitecoreContextRoute;
	}

	if (!SitecoreContextRoute) {
		throw "Project is invalid";
	}

	// wraps the app with:
	// SitecoreContext: provides component resolution and context services via withSitecoreContext
	// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
	return (
		<SitecoreContextRoute
			Router={Router}
			path={path}
			ssrState={ssrState}
			siteName={siteName}
			project={project}
			JssRoute={JssRoute} />
	);
}

export default AppRoot;
